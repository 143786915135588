import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import * as Styled from './styles';
import { HeroBaseProps } from './types';
import { settings } from './const';
import { useScrollTrigger } from './hooks';

import Image from '../../atoms/Image';
import Heading from '../../molecules/Heading';
import ScrollTrigger from '../../atoms/ScrollTrigger';
import { LinkMode } from '../../atoms/Link/enums';
import InfoTile from '../../molecules/InfoTile';

const HeroBase: React.FC<HeroBaseProps> = ({ data, hasTabs, hasContactForm, children }) => {
  const {
    scrollButtonLabel,
    images,
    customTitleTag = 'h1',
    title,
    subtitle,
    contact,
    content,
    contentRichText,
    buttonLink,
    hasTextBgOverlay,
    hasDarkBackground,
  } = data;
  const { ref, onScrollClick } = useScrollTrigger();

  return (
    <Styled.Hero {...{ ref, hasTextBgOverlay, hasTabs, hasContactForm }}>
      <Styled.HeroMediaBox>
        {hasTextBgOverlay && <Styled.HeroOverlay />}
        {images.length === 1 && (
          <>
            <Styled.HeroImage hasMobilePicture={!!images[0].mobileImage?.image}>
              <Image loading="eager" {...images[0].image} />
            </Styled.HeroImage>
            {!!images[0].mobileImage?.image && (
              <Styled.HeroImageMobile>
                <Image loading="eager" {...images[0].mobileImage} />
              </Styled.HeroImageMobile>
            )}
          </>
        )}
        {images.length > 1 && (
          <Slider {...settings}>
            {images.map(({ image, mobileImage, key }) => (
              <Styled.HeroSlide {...{ key }}>
                <Styled.HeroImage hasMobilePicture={!!mobileImage?.image}>
                  <Image loading="eager" {...image} />
                </Styled.HeroImage>
                {mobileImage?.image && (
                  <Styled.HeroImageMobile>
                    <Image loading="eager" {...mobileImage} />
                  </Styled.HeroImageMobile>
                )}
              </Styled.HeroSlide>
            ))}
          </Slider>
        )}
      </Styled.HeroMediaBox>
      <Styled.HeroInner>
        <Styled.HeroContent>
          <Styled.HeroContentInner>
            <Heading {...{ customTitleTag, title, subtitle, content, contentRichText, hasDarkBackground }} />
            {contact && (
              <Styled.HeroInfoList>
                {contact.map(({ key, title: infoTitle, desc }) => (
                  <Styled.HeroInfoListItem {...{ key }}>
                    <InfoTile title={infoTitle} {...{ desc }} />
                  </Styled.HeroInfoListItem>
                ))}
              </Styled.HeroInfoList>
            )}
            {buttonLink?.link.to && (
              <Styled.HeroAction mode={LinkMode.HasBorder} {...buttonLink.link}>
                {buttonLink.label}
              </Styled.HeroAction>
            )}
          </Styled.HeroContentInner>
          {children}
        </Styled.HeroContent>

        {/* Only show scroll button if a label is set */}
        {scrollButtonLabel &&
          <Styled.HeroScroll>
            <Styled.HeroScrollInner>
              <ScrollTrigger
                label={scrollButtonLabel}
                onClick={onScrollClick}
                isDark={hasContactForm}
              />
            </Styled.HeroScrollInner>
          </Styled.HeroScroll>
        }
      
      </Styled.HeroInner>
    </Styled.Hero>
  );
};

export default HeroBase;
