import { graphql } from 'gatsby';
import React from 'react';
import format from 'date-fns/format';
import { Helmet } from 'react-helmet';
import HeroBase from '../../organisms/Hero';
import Sections from '../../sections';
import { PostProps } from './types';
import { getImage } from '../../../utils/sanityTypes';
import * as Styled from './styles';

const Post: React.VFC<PostProps> = ({ data: { sanityPost } }) => {
  const {
    sections = [],
    images,
    firstName,
    lastName,
    publishDate,
    title,
    subtitle,
  } = sanityPost || {};

  return (
    <>
    <Helmet
      htmlAttributes={{
        lang: sanityPost.i18n_lang
      }}
      title={sanityPost?.seo?.title}
      titleTemplate={'%s - Lyngen North'}
      defaultTitle="Glass igloo hotel - Lyngen North"
    >
        {sanityPost?.seo?.title &&
          <meta property="og:title" content={sanityPost.seo.title} />
        }
        {sanityPost?.seo?.keywords &&
          <meta name="keywords" content={sanityPost.seo.keywords} />
        }
        {sanityPost?.seo?.description &&
          <meta property="og:description" content={sanityPost.seo.description} />
        }
        {sanityPost?.seo?.description &&
          <meta name="description" content={sanityPost.seo.description} />
        }
        {sanityPost?.seo?.image?.asset?.url &&
          <meta property="og:image" content={sanityPost.seo.image.asset.url} />
        }
        {sanityPost?.seo?.image?.alt && sanityPost?.seo?.image?.asset?.url &&
          <meta property="og:image:alt" content={sanityPost.seo.image.alt} />
        }

      </Helmet>
      <HeroBase
        data={{
          images: (images ?? []).map((slide) => {
            const { mobileImage, image, _key: key } = slide ?? {};

            return {
              key: key ?? '',
              image: getImage(image),
              mobileImage: getImage(mobileImage),
            };
          }),
          title: title ?? '',
          subtitle,
          content: (
            <Styled.AuthorContainer>
              by{' '}
              <Styled.Author>
                {firstName} {lastName}
              </Styled.Author>
              , {publishDate && format(new Date(publishDate), 'dd MMMM, yyyy')}
            </Styled.AuthorContainer>
          ),
        }}
      />
      <Sections {...{ sections }} />
    </>
  );
};

export default Post;

export const postQuery = graphql`
  query PostDetailsQuery($id: String!, $language: String!) {
    sanityPost(_id: { eq: $id }, i18n_lang: { eq: $language }) {
      id
      title
      subtitle
      i18n_lang
      seo {
        title
        description
        keywords
        image {
          alt
          asset {
            url
          }
        }
      }
      images {
        _key
        mobileImage {
          ...ImageFragment
        }
        image {
          ...ImageFragment
        }
      }
      firstName
      lastName
      publishDate
      sections {
        ... on SanityHeroBaseSection {
          ...HeroBaseSectionFragment
        }
        ... on SanityActivityBookingSection {
          ...ActivityBookingSectionFragment
        }
        ... on SanityHeroWithTabsSection {
          ...HeroWithTabsSectionFragment
        }
        ... on SanityHeroWithContactFormSection {
          ...HeroWithContactFormSectionFragment
        }
        ... on SanityHotelsSection {
          ...HotelsSectionFragment
        }
        ... on SanityTilesSection {
          ...TilesSectionFragment
        }
        ... on SanityHotelsTabsSection {
          ...HotelsTabsSectionFragment
        }
        ... on SanityReviewsCarouselSection {
          ...ReviewsCarouselSectionFragment
        }
        ... on SanityTeamSection {
          ...TeamSectionFragment
        }
        ... on SanityTitleFaqSection {
          ...TitleFaqSectionFragment
        }
        ... on SanityBookNowSection {
          ...BookNowSectionFragment
        }
        ... on SanityVideoBoxSection {
          ...VideoBoxSectionFragment
        }
        ... on SanityWideBannerSection {
          ...WideBannerSectionFragment
        }
        ... on SanityAboutUsSection {
          ...AboutUsSectionFragment
        }
        ... on SanityTextSection {
          ...TextSectionFragment
        }
        ... on SanityTextListSection {
          ...TextListSectionFragment
        }
        ... on SanityTransportScheduleSection {
          ...TransportScheduleSectionFragment
        }
        ... on SanityTransportOptionsSection {
          ...TransportOptionsSectionFragment
        }
        ... on SanityHotelWithFeaturesListSection {
          ...HotelWithFeaturesListSectionFragment
        }
        ... on SanityLocationSection {
          ...LocationSectionFragment
        }
        ... on SanityPostTeasersSection {
          ...PostTeasersSectionFragment
        }
        ... on SanityImagesGallerySection {
          ...ImagesGallerySectionFragment
        }
        ... on SanitySocialIconsSection {
          ...SocialIconsSectionFragment
        }
      }
    }

    sanityFooter(i18n_lang: { eq: $language }) {
      ...FooterFragment
    }

    sanityHeader(i18n_lang: { eq: $language }) {
      ...HeaderFragment
    }

    sanityCookieInfo(i18n_lang: { eq: $language }) {
      ...CookieInfoFragment
    }

    sanityPromoBanner(i18n_lang: { eq: $language }) {
      ...PromoBannerFragment
    }
  }
`;
