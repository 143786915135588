import { useCallback, useRef } from 'react';
import { animateScroll as scroll } from 'react-scroll';

export const useScrollTrigger = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  const onScrollClick = useCallback(() => {
    const { current } = ref;

    if (current && typeof window !== 'undefined') {
      const distanceToScroll = current.getBoundingClientRect().height - window.scrollY;

      scroll.scrollMore(distanceToScroll, {
        duration: 320,
        delay: 100,
        smooth: 'ease',
      });
    }
  }, [ref]);

  return { ref, onScrollClick };
};
