import { ApolloProvider } from '@apollo/client';
import { PageProps } from 'gatsby';
import React, { useMemo } from 'react';
import Page from '../components/templates/Page';
import Post from '../components/templates/Post';
import LocaleContextProvider from '../contexts/LocaleContext/provider';
import { usePreviewDocument } from '../hooks/usePreviewDocument';
import { getPreviewLocale } from '../hooks/usePreviewDocument/utils';
import Layout from '../layouts';
import apolloClient from '../utils/apolloClient';

type WebPreviewPageProps = PageProps<
  GatsbyTypes.PageDetailsQueryQuery | GatsbyTypes.PostDetailsQueryQuery
>;

const WebPreview: React.FC<WebPreviewPageProps> = (props) => {
  const { location } = props;

  const document = usePreviewDocument();

  const children = useMemo(() => {
    if (!document || !window) {
      return null;
    }

    if ('sanityPage' in document && document.sanityPage) {
      return (
        <Page
          {...props}
          data={{
            sanityPage: document.sanityPage,
          }}
        />
      );
    }

    if ('sanityPost' in document && document.sanityPost) {
      return (
        <Post
          {...props}
          data={{
            sanityPost: document.sanityPost,
          }}
        />
      );
    }

    return null;
  }, [document, props]);

  const data = useMemo(() => {
    const { sanityHeader, sanityFooter, sanityCookieInfo, sanityPromoBanner } = document ?? {};

    return {
      sanityHeader,
      sanityFooter,
      sanityCookieInfo,
      sanityPromoBanner,
    };
  }, [document]);

  return <Layout {...{ data, location }}>{children}</Layout>;
};

const WebPreviewWrapper: React.FC<WebPreviewPageProps> = (props) => (
  <LocaleContextProvider locale={getPreviewLocale()}>
    <ApolloProvider client={apolloClient}>
      <WebPreview {...props} />
    </ApolloProvider>
  </LocaleContextProvider>
);

export default WebPreviewWrapper;
