import { graphql } from 'gatsby';
import React from 'react';
import Sections from '../../sections';
import { PageProps } from './types';
import { Helmet } from 'react-helmet';

const Page: React.VFC<PageProps> = ({ data: { sanityPage } }) => {
  const { sections = [] } = sanityPage ?? {};

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: sanityPage.i18n_lang
        }}
        title={sanityPage?.seo?.title}
        titleTemplate={'%s - Lyngen North'}
        defaultTitle="Glass igloo hotel - Lyngen North"
      >

        {sanityPage?.seo?.title &&
          <meta property="og:title" content={sanityPage.seo.title} />
        }
        {sanityPage?.seo?.keywords &&
          <meta name="keywords" content={sanityPage.seo.keywords} />
        }
        {sanityPage?.seo?.description &&
          <meta property="og:description" content={sanityPage.seo.description} />
        }
        {sanityPage?.seo?.description &&
          <meta name="description" content={sanityPage.seo.description} />
        }
        {sanityPage?.seo?.image?.asset?.url &&
          <meta property="og:image" content={sanityPage.seo.image.asset.url} />
        }
        {sanityPage?.seo?.image?.alt && sanityPage?.seo?.image?.asset?.url &&
          <meta property="og:image:alt" content={sanityPage.seo.image.alt} />
        }
      </Helmet>
      <Sections {...{ sections }} />
    </>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageDetailsQuery($id: String!, $language: String!) {
    sanityPage(_id: { eq: $id }, i18n_lang: { eq: $language }) {
      id
      title
      i18n_lang
      seo {
        title
        description
        keywords
        image {
          alt
          asset {
            url
          }
        }
      }
      slug {
        current
      }
      sections {
        ... on SanityHeroBaseSection {
          ...HeroBaseSectionFragment
        }
        ... on SanityActivityBookingSection {
          ...ActivityBookingSectionFragment
        }
        ... on SanityHeroWithTabsSection {
          ...HeroWithTabsSectionFragment
        }
        ... on SanityHeroWithPostsSection {
          ...HeroWithPostsSectionFragment
        }
        ... on SanityHeroWithContactFormSection {
          ...HeroWithContactFormSectionFragment
        }
        ... on SanityHotelsSection {
          ...HotelsSectionFragment
        }
        ... on SanityTilesSection {
          ...TilesSectionFragment
        }
        ... on SanityHotelsTabsSection {
          ...HotelsTabsSectionFragment
        }
        ... on SanityReviewsCarouselSection {
          ...ReviewsCarouselSectionFragment
        }
        ... on SanityTeamSection {
          ...TeamSectionFragment
        }
        ... on SanityTitleFaqSection {
          ...TitleFaqSectionFragment
        }
        ... on SanityBookNowSection {
          ...BookNowSectionFragment
        }
        ... on SanityVideoBoxSection {
          ...VideoBoxSectionFragment
        }
        ... on SanityWideBannerSection {
          ...WideBannerSectionFragment
        }
        ... on SanityAboutUsSection {
          ...AboutUsSectionFragment
        }
        ... on SanityTextSection {
          ...TextSectionFragment
        }
        ... on SanityTextListSection {
          ...TextListSectionFragment
        }
        ... on SanityTransportScheduleSection {
          ...TransportScheduleSectionFragment
        }
        ... on SanityTransportOptionsSection {
          ...TransportOptionsSectionFragment
        }
        ... on SanityHotelWithFeaturesListSection {
          ...HotelWithFeaturesListSectionFragment
        }
        ... on SanityLocationSection {
          ...LocationSectionFragment
        }
        ... on SanityPostTeasersSection {
          ...PostTeasersSectionFragment
        }
        ... on SanityImagesGallerySection {
          ...ImagesGallerySectionFragment
        }
        ... on SanitySocialIconsSection {
          ...SocialIconsSectionFragment
        }
        ... on SanityStepByStepSection {
          ...StepByStepSectionFragment
        }
        ... on SanityPowerConsumptionSection {
          ...PowerConsumptionSectionFragment
        }

      }
    }

    sanityFooter(i18n_lang: { eq: $language }) {
      ...FooterFragment
    }

    sanityHeader(i18n_lang: { eq: $language }) {
      ...HeaderFragment
    }

    sanityCookieInfo(i18n_lang: { eq: $language }) {
      ...CookieInfoFragment
    }

    sanityPromoBanner(i18n_lang: { eq: $language }) {
      ...PromoBannerFragment
    }
  }
`;
