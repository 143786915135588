import styled from 'styled-components';
import Typography from '../../styles/Typography';

export const AuthorContainer = styled(Typography)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 0.875rem;
`;

export const Author = styled(Typography).attrs({ forwardedAs: 'span' })`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
