import { gql } from '@apollo/client';
import fragments from '../../components/sections/sectionsFragments';

export const DOCUMENT_ID_QUERY =
  '*[(_type == "page" || _type == "post") && (_id == $id || ($id in path("drafts.**")))]';

export const DOCUMENT_GRAPHQL_QUERY = gql`
  ${fragments}
  query DocumentPreview($id: String!, $language: String!) {
    sanityPage(_id: { eq: $id }, i18n_lang: { eq: $language }) {
      id
      _id
      title
      sections {
        ... on SanityHeroBaseSection {
          ...HeroBaseSectionFragment
        }
        ... on SanityHeroWithTabsSection {
          ...HeroWithTabsSectionFragment
        }
        ... on SanityHeroWithPostsSection {
          ...HeroWithPostsSectionFragment
        }
        ... on SanityHeroWithContactFormSection {
          ...HeroWithContactFormSectionFragment
        }
        ... on SanityHotelsSection {
          ...HotelsSectionFragment
        }
        ... on SanityTilesSection {
          ...TilesSectionFragment
        }
        ... on SanityHotelsTabsSection {
          ...HotelsTabsSectionFragment
        }
        ... on SanityReviewsCarouselSection {
          ...ReviewsCarouselSectionFragment
        }
        ... on SanityTeamSection {
          ...TeamSectionFragment
        }
        ... on SanityTitleFaqSection {
          ...TitleFaqSectionFragment
        }
        ... on SanityBookNowSection {
          ...BookNowSectionFragment
        }
        ... on SanityVideoBoxSection {
          ...VideoBoxSectionFragment
        }
        ... on SanityWideBannerSection {
          ...WideBannerSectionFragment
        }
        ... on SanityAboutUsSection {
          ...AboutUsSectionFragment
        }
        ... on SanityTextSection {
          ...TextSectionFragment
        }
        ... on SanityTextListSection {
          ...TextListSectionFragment
        }
        ... on SanityTransportScheduleSection {
          ...TransportScheduleSectionFragment
        }
        ... on SanityTransportOptionsSection {
          ...TransportOptionsSectionFragment
        }
        ... on SanityHotelWithFeaturesListSection {
          ...HotelWithFeaturesListSectionFragment
        }
        ... on SanityLocationSection {
          ...LocationSectionFragment
        }
        ... on SanityPostTeasersSection {
          ...PostTeasersSectionFragment
        }
        ... on SanityImagesGallerySection {
          ...ImagesGallerySectionFragment
        }
        ... on SanitySocialIconsSection {
          ...SocialIconsSectionFragment
        }
      }
    }

    sanityPost(_id: { eq: $id }, i18n_lang: { eq: $language }) {
      id
      title
      subtitle
      images {
        _key
        mobileImage {
          ...ImageFragment
        }
        image {
          ...ImageFragment
        }
      }
      firstName
      lastName
      publishDate
      sections {
        ... on SanityHeroBaseSection {
          ...HeroBaseSectionFragment
        }
        ... on SanityHeroWithTabsSection {
          ...HeroWithTabsSectionFragment
        }
        ... on SanityHeroWithContactFormSection {
          ...HeroWithContactFormSectionFragment
        }
        ... on SanityHotelsSection {
          ...HotelsSectionFragment
        }
        ... on SanityTilesSection {
          ...TilesSectionFragment
        }
        ... on SanityHotelsTabsSection {
          ...HotelsTabsSectionFragment
        }
        ... on SanityReviewsCarouselSection {
          ...ReviewsCarouselSectionFragment
        }
        ... on SanityTeamSection {
          ...TeamSectionFragment
        }
        ... on SanityTitleFaqSection {
          ...TitleFaqSectionFragment
        }
        ... on SanityBookNowSection {
          ...BookNowSectionFragment
        }
        ... on SanityVideoBoxSection {
          ...VideoBoxSectionFragment
        }
        ... on SanityWideBannerSection {
          ...WideBannerSectionFragment
        }
        ... on SanityAboutUsSection {
          ...AboutUsSectionFragment
        }
        ... on SanityTextSection {
          ...TextSectionFragment
        }
        ... on SanityTextListSection {
          ...TextListSectionFragment
        }
        ... on SanityTransportScheduleSection {
          ...TransportScheduleSectionFragment
        }
        ... on SanityTransportOptionsSection {
          ...TransportOptionsSectionFragment
        }
        ... on SanityHotelWithFeaturesListSection {
          ...HotelWithFeaturesListSectionFragment
        }
        ... on SanityLocationSection {
          ...LocationSectionFragment
        }
        ... on SanityPostTeasersSection {
          ...PostTeasersSectionFragment
        }
        ... on SanityImagesGallerySection {
          ...ImagesGallerySectionFragment
        }
        ... on SanitySocialIconsSection {
          ...SocialIconsSectionFragment
        }
      }
    }

    sanityFooter(i18n_lang: { eq: $language }) {
      ...FooterFragment
    }

    sanityHeader(i18n_lang: { eq: $language }) {
      ...HeaderFragment
    }

    sanityCookieInfo(i18n_lang: { eq: $language }) {
      ...CookieInfoFragment
    }

    sanityPromoBanner(i18n_lang: { eq: $language }) {
      ...PromoBannerFragment
    }
  }
`;
