import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import debounce from 'lodash.debounce';
import sanityClient from '../../utils/sanityClient';
import { DOCUMENT_GRAPHQL_QUERY, DOCUMENT_ID_QUERY } from './consts';
import { DocumentQueryResult, DocumentQueryVariables } from './types';
import { getDocumentIdFromQueryParams } from './utils';
import { useCurrentLocale } from '../../contexts/LocaleContext/hooks';

export const usePreviewDocument = () => {
  const id = getDocumentIdFromQueryParams();
  const language = useCurrentLocale();

  const { data, refetch } = useQuery<DocumentQueryResult, DocumentQueryVariables>(
    DOCUMENT_GRAPHQL_QUERY,
    {
      skip: !id,
      variables: {
        id: id as string,
        language,
      },
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    const debouncedRefetch = debounce(async () => {
      if (id) {
        refetch({
          id,
        });
      }
    }, 500);

    const subscription = sanityClient.listen(DOCUMENT_ID_QUERY, { id }).subscribe(() => {
      debouncedRefetch();
    });

    return () => subscription.unsubscribe();
  }, [id, refetch]);

  return data?.data;
};
