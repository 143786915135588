import localization from '../../localization';

export const getDocumentIdFromQueryParams = (): string | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  return new URLSearchParams(window.location.search).get('id') ?? '';
};

export const getPreviewLocale = () => {
  const id = getDocumentIdFromQueryParams();

  if (!id) {
    return localization.base;
  }

  const [localeFromId] = id.split('.').slice(-1);

  return (
    localization.languages.find((lang) => lang.name === localeFromId)?.name ?? localization.base
  );
};
